import React from 'react';
import {
    Nav,
    NavLink,
    NavLogo,
    Bars,
    NavMenu,
    // NavBtn,
    // NavBtnLink,
  } from './NavbarElements';
  import logo from '../../images/ashfrrd-ffffff.svg';

  const Navbar = () => {
    return (
      <>
        <Nav>
          <Bars />
    
          <NavMenu>
            <NavLogo to='/'>
                <img src={logo} className="App-logo" alt="logo" />
            </NavLogo>
            <NavLink to='/spotify'>
              Spotify
            </NavLink>
            <NavLink to='/brand'>
              Brand
            </NavLink>
          </NavMenu>
          {/* <NavBtn>
            <NavBtnLink to='/signin'>Sign In</NavBtnLink>
          </NavBtn> */}
        </Nav>
      </>
    );
  };
    
  export default Navbar;