import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import {
  BrowserRouter,
  Routes,
  Route,
  // Link
} from "react-router-dom";
import Home from './pages';
import Spotify from './pages/spotify';
import Brand from './pages/brand';
import Pics from './pages/pics';

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path="/spotify" element={<Spotify />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/pics" element={<Pics />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;