import React from 'react';
import logo from '../images/ashfrrd-5cdb95-to-272729.svg';
  
const Home = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
      </header>
      <p>Website coming soon</p>
    </div>
  );
};
  
export default Home;