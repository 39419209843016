import React from 'react';
import logo from '../images/ashfrrd-5cdb95-to-272729.svg';
import whiteLogo from '../images/ashfrrd-ffffff.svg';
  
const Brand = () => {
  return (
    <div className="App">
      <div className="Logos">
          <div className="logo">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className="logo logo--white">
            <img src={whiteLogo} className="App-logo" alt="whiteLogo" />
          </div>
        </div>
        <div className="Fonts">
            <h1>Website coming soon</h1>
            <h2>Website coming soon</h2>
            <h3>Website coming soon</h3>
            <h4>Website coming soon</h4>
            <p>Website coming soon</p>
            <ul>
                <li>Website coming soon</li>
                <li>Website coming soon</li>
                <li>Website coming soon</li>
            </ul>
        </div>
        <div className="colors">
            <div className="color1"></div>
            <div className="color2"></div>
            <div className="color3"></div>
            <div className="color4"></div>
            <div className="color5"></div>
            <div className="color6"></div>
            <div className="color7"></div>
            <div className="color8"></div>
        </div>
    </div>
  );
};
  
export default Brand;