import React from 'react';
import logo from '../images/ashfrrd-5cdb95-to-272729.svg';

const Pics = () => {
	return (
		<div className="App">
			<header className="App-header">
				<div className="logo">
					<img src={logo} className="App-logo" alt="logo" />
				</div>
			</header>
			<p>Pics page coming soon</p>
		</div>
	);
};

export default Pics;